.contact-page {
  background: transparent;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  z-index: 1;
}

.cantact-form-2 {
  width: 700px;
}

.cantact-form-2 h1 {
  justify-self: center;
}

.cantact-form-2 p {
  text-align: justify;
  justify-self: center;
}

.background-contact {
  position: absolute;
  top: 20%;
  left: 35%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 80%;
  background-position: 0% 50%;
}

.form-group-2 label {
  font-size: 16px;
  margin-bottom: 1%;
}

.form-group-2 {
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-align: left;
}

.form-group-2 input,
.form-group-2 textarea {
  width: 700px;
  background: #000;
  border: 1px solid #fff;
  border-radius: 4px;
  color: white;
  padding: 10px;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {

  .contact-page {
    min-height: auto;
    padding: 4%;
  }

  .cantact-form-2 {
    max-width: 350px;
  }

  .form-group-2 input,
  .form-group-2 textarea {
    width: 100%;
  }
}