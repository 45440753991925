.Blog {
    margin: 5%;
    justify-content: start;
    display: grid;
    grid-template-columns: 40% 60%;
    background: linear-gradient(45deg, #000, #1a1a1a);
    gap: 2%;
}

.Blog-pic-main img {
    width: 100%;
}

.Blog-Pic {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    max-width: 100%;
    flex-wrap: nowrap;
}

.Blog-Pic img {
    max-height: 500px;
    object-fit: cover;
}


.Blog-text {
    width: 70%;
    text-align: left;
}

.small-spacing p {
    margin-bottom: 2px;
}

.small-spacing h3 {
    margin-top: 2px;
    font-weight: bold;
    font-size: x-large;
}

.Blog-descript p {
    font-size: larger;
    text-align: justify;
}

.more-link {
    display: inline-flex; 
    align-items: center; 
    gap: 3px; 
    text-decoration: none;
    color: #fff; 
    font-weight: bold;
  }
  
  .more-icon {
    transition: transform 0.3s ease;
  }
  
  .more-link:hover {
    color: #fff; 
    text-decoration: underline;
  }
  
  .more-link:hover .more-icon {
    transform: rotate(45deg); 
  }
  

.Blog-sub {
    margin: 5%;
}

.Blog-sub img {
    width: 50%;
}

.text-sub-blog h1{
    font-size: 54px;
}

.text-sub-blog h2{
    text-align: justify;
    margin: 3rem 0;
}

.TY{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.TY img{
    width: 100%;
    max-width: 500px;
    height: auto;
}

.TY h2{
    margin: 3rem 0;
    text-align: justify;
}

@media screen and (max-width: 600px) {
    .Blog {
        display: block;
        margin: 10%;
    }

    .Blog-text {
        width: 100%;
    }

    .Blog-text h3 {
        font-size: small;
    }

    .text-sub-blog h2{
        font-size: 16px;
    }

    .Blog-descript p {
        text-align: justify;
        font-size: smaller;
        width: 100%
    }

    .text-sub-blog h1{
        font-size: 24px;
        font-weight: 500;
    }
}

@media (max-width: 768px) {
    .Blog {
        display: block;
        margin: 10%;
    }

    .Blog-text {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .Blog {
        display: block;
        margin: 10%;
    }

    .Blog-text {
        width: 100%;
    }
}