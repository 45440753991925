body{
    overflow: hidden;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #000;
    padding: 20px;
}

.login-card {
    width: 90%;
    max-width: 450px;
    background-color: transparent;
    padding: 30px;
    text-align: center;
}

.login-card h3,
.login-card h1 {
    color: #fff;
}

.login-card a {
    color: #fff;
    font-style: italic;
    font-weight: bold;
    text-decoration: underline
}

.login-card span{
    color: #fff;
    background-color: transparent;
    border: none;
    align-self: left;
}

.login-title {
    color: #fff;
    font-weight: 700;
}

.login-subtitle {
    color: #fff;
    margin-bottom: 20px;
    display: block;
}

/* Label text color */
.login-form .ant-form-item-label>label {
    color: #fff;
}

.login-form .ant-input,
.login-form .ant-input-password {
    background-color: black ;
    border-block-end: 1px solid #fff;
    color: white;
    width: 100%;
    max-width: 350px;
    padding: 10px;
}

.login-form .ant-input::placeholder,
.login-form .ant-input-password input::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;

}

.ant-input-outlined {
    border-style: none;
    border-radius: 0;
}

.login-form .ant-btn-primary {
    background: black;
    border: 1px solid #fff;
    font-weight: bold;
    width: 100%;
    max-width: 350px;
    padding: 10px;
    border-radius: 5px;
}

/* Hover effect */
.login-form .ant-btn-primary:hover {
    background: #ffffff56 !important;
    color: #000 !important;
    transition: 0.3s ease-in-out;
    outline: transparent !important;
}

.login-form .ant-btn-primary h4:hover{
    color: #000 !important;
}

.login-form .ant-input:hover,
.login-form .ant-input:focus,
.login-form .ant-input-password:hover,
.login-form .ant-input-password:focus {
    border-color: #fff !important;
    background-color: black !important; 
    box-shadow: none !important; 
    outline: transparent !important;
}

.login-head{
    display: flex;
    gap: 2rem;
    align-content: center;
}

.login-head span{
    align-self: center;
}

@media (max-width: 600px) {
    .login-card {
        width: 100%;
        padding: 20px;
    }

    .login-subtitle{
        font-size: medium;
    }

    .login-form .ant-input,
    .login-form .ant-input-password,
    .login-form .ant-btn-primary {
        width: 100%;
    }
}