.Ourwork {
    margin: 4% 5%;
    gap: 10rem;
    display: flex;
    flex-direction: column;
    text-align: justify;

}

.carousel-container {
    width: 100%;
    overflow: hidden;
}

.carousel-item {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    will-change: transform;
}

.carousel-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
}

.swiper-pagination-bullet {
    background-color: #fff !important;
}

.swiper-pagination-bullet-active {
    background-color: #871212 !important;
}

.swiper-button-prev,
.swiper-button-next {
    color: #ffffffb7 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: #871212 !important;
    padding: 20px;
}

@keyframes rainbow {
    0% {
        border-image-source: linear-gradient(90deg, #009365, #5aff75, #ffcc00, #fe8c12, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365);
    }

    25% {
        border-image-source: linear-gradient(90deg, #5aff75, #ffcc00, #fe8c12, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365, #009365);
    }

    50% {
        border-image-source: linear-gradient(90deg, #ffcc00, #fe8c12, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365, #009365, #5aff75);
    }

    75% {
        border-image-source: linear-gradient(90deg, #fe8c12, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365, #009365, #5aff75, #ffcc00);
    }

    100% {
        border-image-source: linear-gradient(90deg, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365, #009365, #5aff75, #ffcc00, #fe8c12);
    }
}

.production-section {
    padding: 1% 10%;
    border-width: 5px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #009365, #5aff75, #ffcc00, #fe8c12, #f12258, #f12258, #fe8c12, #ffcc00, #5aff75, #009365);
    animation: rainbow 6s linear infinite;
    transition: opacity 1s ease-in-out;
    text-align: center;
}



.background-video-ourwork {
    padding: 1%;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    object-fit: cover;
    pointer-events: none;
}

.carousel-image-production {
    height: 200px;
    width: 350px;
    object-fit: cover;
    transition: transform 0.3s ease-out;
}

.text-production{
    text-align: justify;
}

.text-production a{
    color: #ffcc00;
}

.text-production a:hover{
    color: #ffcc00;
}

.more-link{
    display: inline-flex;
    gap: 5px; 
    text-decoration: none; 
    color: #ffcc00;
    font-weight: bold;
  }
  
  .more-icon {
    transition: transform 0.3s ease; 
  }
  
  .more-link:hover {
    color: #ffcc00; 
  }
  
  .more-link:hover .more-icon {
    transform: rotate(45deg);
  }
  

.video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
}

.video-container iframe{
    width: 100%;
    height: 800px;
}
.video-display {
    height: 500px;
    background: black;
}

.background-videography {
    width: 100%;
}

.Photo h1,
.Video h1,
.Production h1 {
    font-weight: normal;
}

.Photo p,
.Video p,
.Production p {
    font-weight: lighter;
    font-size: 18px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    transition: background 0.3s;
}

.arrow:hover {
    color: #871212;
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.background-videography {
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    object-fit: cover;
    pointer-events: none;
}

.category-buttons {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.category-button {
    color: white;
    background: transparent;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
}

.modal-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.modal-image {
    width: 100%;
    height: auto;
    border-radius: 2px;
}

@media (max-width: 600px) {
    .text-content {
        padding: 10px;
    }

    .carousel-item {
        height: 300px;
    }

    .carousel-item img,
    .carousel-image-production {
        width: 100%;
        height: auto;
    }

    .video-display {
        width: 100%;
    }

    .arrow {
        font-size: 1rem;
        padding: 5px;

    }

    .background-video-ourwork {

        height: 100%;
    }

    .video-container iframe{
        height: 100%;
    }
    

}