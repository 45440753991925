.About{
    margin: 5%;
    text-align: justify;
    word-break: keep-all;
}

.about-pic-bg img {
  width: 100%;
  max-height: 100vh;
}

  @media (max-width: 600px) {
    .text-about{
      font-size: smaller;
    }

    .text-about-container img{
      width: 7%;
    }

    .gallery-about{
      flex-direction: column;
    }
  }
