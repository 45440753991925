/* -------- Main Container -------- */
.Services {
    max-width: 100%;
    margin: 50px auto;
    margin: 5% 10% ;
    text-align: start;
}

/* -------- Header -------- */
.Services h1 {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 40px;
    color: #fff;
}

/* -------- Sections Layout -------- */
.service-nofame{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4%;
    align-items: center;
    margin-bottom: 50px;
}

.service-nf {
    display: flex;
    flex-direction: column;
}

.Services hr{
    margin: 5% 0;
}
   
/* -------- Content Text -------- */
.Services p {
    text-align: left;
    color: #ccc;
}

.service-nofame-1{
    font-size: 1.1rem;
    font-weight: 300;
    text-align: justify;

}

.nofame-text{
    font-style: italic;
    font-size: 2.5rem;
    text-transform: uppercase;
    position: relative;
}

.service-nofame-2{
    font-size: 1.1rem;
    padding: 1%;
    text-align: justify;
    align-self: flex-start; 
}

.service-nofame-2 h4,
.service-nofame-2 p{
    margin: 0;
    text-align: justify;

}

.service-nf-1{
    font-size: 1.1rem;
    font-weight: 300;
    text-align: justify;
    margin-bottom: 5%;
}

.service-nf-1 b{
    font-style: italic;
    font-weight: 100;
    font-size: 2rem;
    text-transform: uppercase;
}

.service-nf-2{
    font-size: 1.1rem;
    text-align: justify;
    align-self: flex-start; 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
}

.service-nf-2 h4,
.service-nf-2 p{
    margin: 0;
    text-align: justify;
    
}

.service-nf-CUBORO h4{
    text-decoration: underline;
}

.service-highlight {
    position: relative;
    font-weight: bold;
    color: #ffffff;
    display: inline-block;
}

.service-nf-enter{
    text-decoration: 2px underline #fff;
}

.service-highlight::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #000, #681515, #871212, #681515, #000);
    background-size: 200% 100%;
    animation: underlineMove 1.5s linear infinite;
}

@keyframes underlineMove {
    0% { background-position: 0% 50%; }
    100% { background-position: 200% 50%; }
}

.animated-title {
    padding-top: .4%;
    position: relative;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;

}

.title-svg {
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 100%;
    height: 20px;
    overflow: visible;
}

.title-path {
    fill: none;
    stroke: #871212a9;
    stroke-width: 10;
    stroke-dasharray: 180;
    stroke-dashoffset: 180;
    animation: drawPath 2s ease-in-out infinite alternate;
}

@keyframes drawPath {
    0% {
        stroke-dashoffset: 180;
        stroke-opacity: 0;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-opacity: 5;
    }
}


/* -------- Responsive Design -------- */
@media screen and (max-width: 1024px) {
    .service-nofame, .service-nf {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .service-nofame div, .service-nf div {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .Services {
        padding: 40px 5%;
    }

    .Services h1 {
        font-size: 2.5rem;
    }

    .Services p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .Services h1 {
        font-size: 2rem;
    }

    .service-nofame, .service-nf{
        text-align: left;
    }

    .service-nf-2{
        display: block;
        gap: 8%;
    }
}
