@import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');

html, body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-optical-sizing: auto;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  overflow-x: hidden;
}


h1,h2,h3 {
  font-weight: 200;
}