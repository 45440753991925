.Home {
    margin: 5%;
}

::selection {
    background-color: #871212;
    color: white;
}

.background-video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    pointer-events: none;
    z-index: -1;
}

.media {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100vw;
    z-index: 1;
    animation: fadeIn 2s ease-in-out;
}

.media h1 {
    font-size: 48px;
    font-weight: 300;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.ant-layout {
    background-color: rgb(0, 0, 0);
}

.ant-layout-content {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.ant-layout-footer {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 0;
}

.section-we {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 600px;
    padding: 40px;
}

.text-section,
.text-section2,
.image-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    gap: 2%;
}

.text-section h1,
.text-section h2,
.text-section2 h2 {
    top: 16px;
    left: 16px;
    color: white;
    margin: 0;
}

.text-section h1 {
    font-weight: 400;
    font-size: 40px;
}


.text-section img {
    bottom: 16px;
    left: 16px;
    height: auto;
}

.image-section img {
    height: 100%;
    align-self: center;
    top: 50%;
    bottom: 50%;
}

.text-section2 img {
    top: 16px;
    right: 16px;
    height: auto;
}

.text-section2 h2 {
    bottom: 16px;
    font-size: 19.5px;
    text-align: justify;
}

.section-service {
    display: grid;
    margin: 5% 0;
    grid-template-columns: 16% 28% 28% 28%;
}

.services {
    padding: 40px;
}

.services-menu {
    padding: 20px;
    border-left: 1px solid #fff;
}

.services h1 {
    font-size: 40px;
    font-weight: 400;
}

.services-menu:hover {
    background-color: #871212b9;
    transition: background-color 600ms;
}

.services-menu h1 {
    font-size: 40px;
    font-weight: 400;
}

.services-menu p {
    padding-top: 5%;
    text-align: left;
}

.services-menu a {
    color: #fff;
}

.section-upcoming {
    display: grid;
    position: relative;
    grid-template-columns: 20% 80%;
    padding: 0% 40px;
    align-items: center;
}

.text-upcoming h1 {
    font-size: 40px;
    font-weight: 400;
}

.pic-upcoming {
    position: relative;
    text-align: center;
    padding-left: 10%;
    z-index: 0;
    cursor: pointer;
}

.pic-upcoming img {
    display: block;
    width: 100%;
    filter: grayscale(100%) blur(1px);
}

.pic-upcoming img:hover {
    filter: grayscale(25%) blur(0px);
    transition: filter 700ms;
}

.event-name {
    position: absolute;
    top: 90%;
    left: 30%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.event-name h3 {
    font-size: 32px;
    font-weight: 600;
    color: white;
    -webkit-text-stroke: 2px black;
    paint-order: stroke fill;
}


.section-ourwork {
    display: flex;
    flex-direction: row;
}

.gallery-section {
    padding: 40px;
    color: white;
}

.gallery-section h2 {
    font-weight: 400;
    font-size: 40px;
}

.gallery-grid2 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
}

.gallery-item2 {
    flex: 1 1 310px;
    overflow: hidden;
}

.gallery-item2 img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item2 img:hover {
    transform: scale(1.05);
}

.more-section {
    text-align: center;
}

.more-section a {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: 2px;
    color: #fff;
    cursor: pointer;
}

.more-section a:hover {
    color: #871212;
}

.section-contact {
    padding: 40px;
    display: grid;
    position: relative;
    grid-template-columns: 60% 40%;
}

.contact-pic {
    width: 60%;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.gallery-item {
    width: auto;
}

.gallery-item img {
    object-fit: cover;
    width: 200px;
    display: block;
}

.item-1 {
    height: 500px;
}

.item-2 {
    height: 450px;
}

.item-3 {
    height: 400px;
}

.item-4 {
    height: 350px;
}

.contact-form-container {
    background-color: transparent;
    color: white;
    text-align: center;
    border-radius: 8px;
}

.contact-form {
    justify-content: end;
    gap: 20px;
    text-align: justify;

}

.contact-form h1 {
    text-align: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: right;
    margin-bottom: 20px;
}

.form-group label {
    font-size: 16px;
    margin-bottom: 1%;
}

.form-group input {
    padding: 10px;
    background: #000;
    border: 1px solid #fff;
    border-radius: 4px;
    color: white;
}

.form-group textarea {
    width: 100%;
    padding: 10px;
    background: #000;
    border: 1px solid #fff;
    border-radius: 4px;
    color: white;
    margin-bottom: 20px;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #fff;
}

.send-button {
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    border: 2px solid transparent;
    font-size: 18px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: background 0.3s ease;
    border-radius: 4px;
}

.send-button:hover {
    color: #871212b9;
}

.contact-text-first h1 {
    font-size: 28px;
    font-weight: 450;
    text-align: center;
    letter-spacing: 8px;
}

.contact-text-first p{
    font-size: 18px;
    font-weight: 200;
}

.contact-text-first h2{
    font-size: 24px;
}

.contact-text-first h2,
.contact-text-first p{
    text-align: center;
}

/* phone */
@media (max-width: 600px) {
    .Home {
        margin: 0;
    }

    .background-video {
        object-fit: none;
        background-color: black;
    }

    .media h1 {
        font-size: 26px;
        font-weight: 400;
    }

    .section-we {
        display: block;
        height: max-content;
    }

    .text-section,
    .text-section2,
    .image-section {
        display: block;
        justify-content: space-between;
        overflow: hidden;
        gap: 5%;
    }

    .text-section img,
    .text-section2 img,
    .image-section img {
        width: 100%;
    }

    .text-section2 h2 {
        margin-top: 2%;
        text-align: justify;
    }

    .text-section h2 {
        margin-bottom: 2%;
    }

    .section-service {
        display: block;
        margin: 5%;
    }

    .section-upcoming {
        display: flex;
        flex-direction: column;
        position: unset;
    }

    .pic-upcoming {
        position: unset;
        padding-left: 0;
    }

    .pic-upcoming img {
        width: 100%;
    }

    .event-name {
        position: unset;
        top: 0%;
        left: 0%;
        transform: none;
    }

    .event-name h3 {
        font-size: smaller;
    }

    .section-contact {
        display: block;
    }

    .contact-pic {
        display: none;
    }

    .contact-form-container {
        padding: 0;
    }

    .contact-form p {
        width: 100%;
    }

    .contact-text-first b {
        font-size: 18px;
        
    }
}

/* tablet */
@media (max-width: 768px) {
    .section-we {
        display: block;
        height: max-content;
    }

    .text-section,
    .text-section2,
    .image-section {
        display: block;
        justify-content: space-between;
        overflow: hidden;
        gap: 5%;
    }

    .text-section img,
    .text-section2 img,
    .image-section img {
        width: 100%;
    }

    .text-section2 h2 {
        margin-top: 2%;
        text-align: justify;
    }

    .text-section h2 {
        margin-bottom: 2%;
    }

    .section-service {
        display: block;
        margin: 5%;
    }

    .section-upcoming {
        display: flex;
        flex-direction: column;
        position: unset;
    }

    .pic-upcoming {
        position: unset;
        padding-left: 0;
    }

    .pic-upcoming img {
        width: 100%;
    }

    .event-name {
        position: unset;
        top: 0%;
        left: 0%;
        transform: none;
    }

    .event-name h3 {
        font-size: smaller;
    }

    .section-contact {
        flex-direction: column;
    }

    .contact-pic {
        display: none;
    }

    .contact-form-container {
        padding: 0;
    }
}

/* laptops */
@media (max-width: 992px) {
    .section-service {
        display: block;
        margin: 5%;
    }

    .section-upcoming {
        display: flex;
        flex-direction: column;
        position: unset;
    }

    .pic-upcoming {
        position: unset;
        padding-left: 0;
    }

    .pic-upcoming img {
        width: 100%;
    }

    .event-name {
        position: unset;
        top: 0%;
        left: 0%;
        transform: none;
    }

    .event-name h3 {
        font-size: smaller;
    }

}

/* large */
@media (min-width: 1200px) {
    .event-name {
        position: absolute;
        left: 50%;
    }

    .contact-pic {
        width: 70%;
    }

    .gallery-item {
        width: 50%;
    }

    .gallery-item img {
        width: 100%;
        object-fit: cover;
        display: block;
    }

}