.header-container {
    z-index: 10;
    font-weight: 200;
    line-height: initial;
}

.custom-menu {
    position: fixed;
    font-size: 20px;
    display: flex;
    padding: 0 5px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.712);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.829);
    width: 100vw;
    top: 0;
    z-index: 2;
}

.custom-menu .ant-menu-item a {
    color: white;
    padding: 8px 16px;
    text-decoration: none;
}

.custom-menu .menu-center-group a:hover {
    color: #fff;
    text-decoration: underline 1px #fff;
    text-underline-offset: 3px;
    /* text-decoration: line-through 2px #871212a1; */
    animation-duration: 3000ms;
}

.menu-right-web {
    padding: 15px;
}

.menu-right-web a {
    width: 50px;
}

.custom-menu .menu-right-web a:hover {
    color: #8d8d8d;
}

.menu-left {
    flex: 1;
    display: flex;
    padding-top: 20px;
    text-align: center;
}

.menu-logo {
    display: flex;
    align-items: center;
}

.menu-center-group {
    display: flex;
    flex: 2;
    justify-content: center;
}

.menu-left-web {
    padding-top: 10px;
}

.menu-right {
    flex: 1;
    display: flex;
    justify-content: end;
}

.custom-menu .ant-menu-item {
    background: transparent;
}

.ant-layout-header {
    padding: 0;
    list-style: 0;
    height: 0;
}

.header-container button {
    background: none;
    border: 0;
}

.custom-menu-phone {
    display: flex;
    padding: 0 10px;
    align-items: stretch;
    background-color: rgba(0, 0, 0);
    box-shadow: 0 10px 100px rgba(0, 0, 0, 0.7);
    width: 100vw;
    top: 0;
    z-index: 2;
}

.menu-right-phone {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 20px;
    padding-top: 12px;
}

.menu-right-phone a {
    color: #fff;
}

.menu-right-phone a:hover {
    color: #871212a1;
}

.ant-drawer .ant-drawer-content {
    background: #000;
    color: #fff;
    width: 100vw;
}

.custom-drawer .ant-drawer-content {
    background-color: transparent;
}

.custom-drawer .ant-drawer-title {
    color: #fff;
}

.custom-drawer .ant-menu-item {
    background: transparent;
    color: #fff;
}

.ant-drawer .ant-drawer-close {
    color: #fff;
}

.custom-drawer ul {
    background: transparent;
    color: #fff;
}

.custom-drawer .ant-menu-item:hover {
    background-color: #444;
}

.logo-mobile {
    padding-top: 10px;
}

.active-menu-item a {
    color: white !important;
    text-decoration: underline;
    font-weight: bold;
}

/* phone */
@media (max-width: 600px) {
    .ant-layout-header {
        height: 64px;
        background-color: #000;
    }
}

/* tablet */
@media (max-width: 768px) {}

/* laptops */
@media (max-width: 992px) {
    .custom-menu {
        font-size: 12px;
        padding: 0 5px;
        align-items: center;
    }
}

/* large */
@media (max-width: 1200px) {}